<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-file-input
      accept="video/*"
      v-model="form.video_file"
      :rules="MIXINS_REQUIRED_RULES"
      label="Video File"
      dense
      filled
      class="rounded-lg"
    />
    <v-text-field
      v-model="form.label"
      label="Label"
      dense
      filled
      class="rounded-lg"
    />
    <v-textarea
      v-model="form.description"
      label="Video Description"
      dense
      filled
      rows="3"
      class="rounded-lg"
    />
      <!-- :rules="MIXINS_REQUIRED_RULES" -->
  </v-form>
</template>

<script>
export default {
  data: () => ({
    valid: false
    // timePicker: false
  }),
  props: {
    /**
     * Collection of data that will be supplied to this component
     */
    form: {
      type: Object,
      default: () => {
        return {};
      }
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>
